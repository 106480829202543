import axios from "axios";

// GET: keep simple and return only data or null
export const getProductsApi = async (data = {}) => {
  const response = await axios.get('/products/', { ...data }, {withCredentials: true})
  return response.status === 200 ? response.data : null
}

export const getProductsByTypeApi = async (typeId) => {
  if (typeId === null) return null
  const response = await axios.get(`/products/by-type/${typeId}`, { withCredentials: true })
  return response.status === 200 ? response.data : null
}

// POST to create if no id property on data
// PATCH to update if id property on data
// return whole request object for custom handling
export const setProductsApi = async (data) => {
  if (data.hasOwnProperty('id'))
    return await axios.patch(`/products/${data.id}/`, { ...data }, { headers: {
      'Content-Type': 'multipart/form-data',
    }, withCredentials: true})
  else
    return await axios.post('/products/', { ...data }, { headers: {
      'Content-Type': 'multipart/form-data',
    }, withCredentials: true})
}

// alphabeticly re-order group / category
const ordering = (categories) => {
  const sortedData = categories.sort((a, b) => a.name.localeCompare(b.name))
  return sortedData
}

// categorization retrieval
export const getProductsCategoryCompraMEDApi = async () => {
  const response = await axios.get('/products/categories?group=Saúde', { withCredentials: true })
  return response.status === 200 ? response.data : null
}

export const getProductsCategoryCompraSERVApi = async () => {
  const response = await axios.get('/products/categories', { withCredentials: true })
  return response.status === 200 ? ordering(response.data) : null
}

export const getProductsOriginsApi = async () => {
  const response = await axios.get('/products/origins', { withCredentials: true })
  return response.status === 200 ? response.data : null
}
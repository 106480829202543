import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { 
    Stack, 
    Heading, 
    Section, 
} from '@carbon/react';
import { useAuth } from '../contexts/AuthContext';
import { UiShell } from '../containers/Template'
import { DataCRUD } from '../containers/DataCRUD'

// api imports
import { getSuppliersApi, setSuppliersApi } from '../utils/api/suppliers'
import { getStatesApi, getCitiesApi } from '../utils/api/location'
import { getUsersApi } from '../utils/api/accounts'

// DataManager form setup
const form = [
    [
        {
            label: 'CNPJ',
            helper: '',
            type: 'cnpj',
            key: 'cnpj'
        },
        {
            label: 'Nome Fantasia',
            helper: '',
            type: 'text',
            key: 'name'
        },
        {
            label: 'Razão Social',
            helper: '',
            type: 'text',
            key: 'social_name'
        }
    ],
    [
        {
            label: 'CEP',
            helper: '',
            type: 'cep',
            key: 'cep'
        },
        {
            label: 'Endereço',
            helper: '',
            type: 'text',
            key: 'endereco'
        },
        {
            label: 'Número',
            helper: '',
            type: 'text',
            key: 'numero'
        }
    ],
    [
        {
            label: 'Complemento',
            helper: '',
            type: 'text',
            key: 'complemento'
        },
        {
            label: 'UF',
            helper: '',
            type: 'select',
            fetchApi: getStatesApi,
            optionText: 'abbreviation',
            key: 'uf'
        },
        {
            label: 'Cidade',
            helper: '',
            type: 'select',
            fetchApi: getCitiesApi,
            fetchApiParam: 'uf', // use param from key reference...
            optionText: 'name',
            key: 'cidade'
        }
    ],
    [
        {
            label: 'Contrato Número',
            helper: '',
            type: 'text',
            key: 'contract_number'
        },
        {
            label: 'Taxa Administrativa (%)',
            helper: '',
            type: 'percentage',
            key: 'platform_admin_rate'
        },
        {
            label: 'Firmado em',
            helper: '',
            type: 'date',
            key: 'contract_start_date'
        }
    ],
    [
        {
            label: 'Status do Contrato',
            helper: '',
            type: 'select',
            data: [
                {
                    name: 'Inativo',
                    id: 0
                },
                {
                    name: 'Ativo',
                    id: 1
                }
            ],
            default: 1,
            optionText: 'name',
            key: 'contract_status'
        },
        {
            label: 'Telefone',
            helper: '',
            type: 'phone',
            key: 'phone'
        },
        {
            label: 'E-mail',
            helper: '',
            type: 'text',
            key: 'email'
        }
    ],
    [
        {
            label: 'Valor Mínimo de Pedido',
            helper: '',
            type: 'money',
            key: 'minimun_order_amount'
        },
        {},
        {}
    ]
]

// DataGrid headers and data table formating setup
const dataGridHeader = [
    {
        header: 'CNPJ',
        key: 'cnpj'
    },
    {
        header: 'Razão Social',
        key: 'social_name'
    },
    {
        header: 'UF',
        key: 'uf_code'
    },
    {
        header: 'Contrato Número',
        key: 'contract_number'
    },
    {
        header: 'Status',
        key: 'contract_status_code'
    },
    {
        header: 'Firmado em',
        key: 'contract_start_date'
    }
]

const dataGridSearchBy = dataGridHeader.map(item => item.key.endsWith('_code') ? item.key.slice(0, -5) : item.key)

const dataGridFilterBy = [
    { 
        label: 'Todos',
        query: null
    },
    { 
        label: 'Ativos',
        query: [
            {
                key: 'contract_status',
                value: 1
            }
        ]
    },
    { 
        label: 'Inativos',
        query: [
            {
                key: 'contract_status',
                value: 0
            }
        ]
    }
]

const Suppliers = () => {
    const { user } = useAuth()
    const backoffice = process.env.REACT_APP_BACKOFFICE
    const canAdd = (user.permissions.includes('add_supplier') && backoffice === 'True') || user.is_staff
    const canChange = (user.permissions.includes('change_supplier') && backoffice === 'True') || user.is_staff

    // DataCRUD callbacks
    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
        }
    }

    const onCreateData = (result) => {
        // update object data?
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data created: ${result}`)
        }
    }

    return (
        <UiShell>
            <Stack gap={8}>
                <Section>
                    <Heading className="primary">Fornecedores</Heading>
                    <p>Rede de fornecedores credenciados</p>
                </Section>
                <Section>
                    <DataCRUD 
                        title={'Fornecedores'} 
                        desc={'Todos os cadastros'} 
                        headers={dataGridHeader} 
                        searchBy={dataGridSearchBy}
                        filters={dataGridFilterBy}

                        getApi={getSuppliersApi}

                        canAdd={canAdd}
                        canChange={canChange}
                        form={form}
                        formTitleKey={'name'}
                        setApi={setSuppliersApi}
                        onSave={onSaveData}
                        onCreate={onCreateData}
                    /> 
                </Section>
            </Stack>
        </UiShell>
    )
}

export default Suppliers
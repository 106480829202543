import './App.scss'
import axios from 'axios'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './pages/Login'
import Home from './pages/Home'
import UserProfile from './pages/UserProfile'
import PublicEntities from './pages/PublicEntities'
import LinkedPublicEntities from './pages/LinkedPublicEntities'
import Operators from './pages/Operators'
import Suppliers from './pages/Suppliers'
import SupplierOperators from './pages/SupplierOperators'
import Products from './pages/Products'
import Services from './pages/Services'
import BalanceContingency from './pages/BalanceContingency'
import Orders from './pages/Orders'
import Quotes from './pages/Quotes'
import Support from './pages/Support'
import Reports from './pages/Reports'
import UserManual from './pages/UserManual'
import { AuthProvider } from './contexts/AuthContext'
import PrivateRoute from './components/PrivateRoute'

// axios setup
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:8000/api'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.validateStatus = function (status) {
  //return status >= 200 && status < 500; // Resolve only if the status code is between 200 and 499
  return status >= 200 && status < 500 && status !== 401; // Resolve only if the status code is between 200 and 499
}

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login />}/>
                    <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>}/>
                    <Route path="/profile" element={<PrivateRoute><UserProfile /></PrivateRoute>}/>
                    <Route path="/entities" element={<PrivateRoute><PublicEntities /></PrivateRoute>}/>
                    <Route path="/linked-entities" element={<PrivateRoute><LinkedPublicEntities /></PrivateRoute>}/>
                    <Route path="/operators" element={<PrivateRoute><Operators /></PrivateRoute>}/>
                    <Route path="/suppliers" element={<PrivateRoute><Suppliers /></PrivateRoute>}/>
                    <Route path="/supplier-operators" element={<PrivateRoute><SupplierOperators /></PrivateRoute>}/>
                    <Route path="/products" element={<PrivateRoute><Products /></PrivateRoute>}/>
                    <Route path="/services" element={<PrivateRoute><Services /></PrivateRoute>}/>
                    <Route path="/balance" element={<PrivateRoute><BalanceContingency /></PrivateRoute>}/>
                    <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>}/>
                    <Route path="/quotes" element={<PrivateRoute><Quotes /></PrivateRoute>}/>
                    <Route path="/support" element={<PrivateRoute><Support /></PrivateRoute>}/>
                    <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>}/>
                    <Route path="/manual" element={<PrivateRoute><UserManual /></PrivateRoute>}/>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    )
}

export default App;
